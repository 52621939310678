import clsx from "clsx";

export function Woobly({ children, class: classNames, ...props }) {
  function processText(text, offset = 0) {
    return text.split("").map((char, i) => (
      <span
        style={{
          animationDelay: `${-(text.length - (i + offset)) / 4}s`,
        }}
      >
        {char}
      </span>
    ));
  }

  function processChildren(children, textOffset = 0) {
    if (typeof children === "string") {
      return processText(children, textOffset);
    }

    if (Array.isArray(children)) {
      let offset = textOffset;
      return children.map((child) => {
        const processed = processChildren(child, offset);
        if (typeof child === "string") {
          offset += child.length;
        }
        return processed;
      });
    }

    if (children?.props) {
      return {
        ...children,
        props: {
          ...children.props,
          children: processChildren(children.props.children, textOffset),
        },
      };
    }

    return children;
  }

  return (
    <span class={clsx("woobly", classNames)} {...props}>
      {processChildren(children)}
    </span>
  );
}

export function FunnyLink({ children, class: classNames, ...props }) {
  return (
    <a class={clsx("group", classNames)} {...props}>
      <span class="group-hover:hidden">{children}</span>
      <span class="hidden group-hover:inline">
        <Woobly>{children}</Woobly>
      </span>
    </a>
  );
}

export function Shaded({ children, class: classNames, ...props }) {
  return (
    <h1
      class={classNames}
      style={{
        "--shadowColour": "#465350",
        textShadow:
          "1px 1px 0px color-mix(in srgb, var(--shadowColour) 100%, transparent), 2px 2px 0px color-mix(in srgb, var(--shadowColour) 75%, transparent), 3px 3px 0px color-mix(in srgb, var(--shadowColour) 50%, transparent), 4px 4px 0px color-mix(in srgb, var(--shadowColour) 25%, transparent), 5px 5px 0px color-mix(in srgb, var(--shadowColour) 10%, transparent)",
      }}
      {...props}
    >
      <Woobly>{children}</Woobly>
    </h1>
  );
}
