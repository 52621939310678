import { BackgroundCanvas } from "£/BackgroundCanvas.jsx";
import { useReducer, useState } from "preact/hooks";
import { Woobly, FunnyLink, Shaded } from "£/Woobly.jsx";

function /* Page Break */ Pb() {
  return <div class="mt-4" />;
}

function Hl({ children }) {
  return (
    <span class="bg-grime-50 p-[0.2em] rounded-sm italic">{children}</span>
  );
}

function Quote({ author, children }) {
  return (
    <li class="list-none">
      <q>{children}</q>
      <br />
      <i class="block text-right">~ {author}</i>
    </li>
  );
}

function Card({ children }) {
  return (
    <div
      class="cardBg p-4 border border-grime-200 group"
      style={{ animationDelay: `${Math.random() * -1}s` }}
    >
      <div class="w-full h-full text-white">
        <span class="group-hover:hidden">{children}</span>
        <span class="hidden group-hover:inline">
          <Woobly>{children}</Woobly>
        </span>
      </div>
    </div>
  );
}

function IndexPage() {
  return (
    <div class="relative min-h-screen overflow-hidden">
      <BackgroundCanvas class="bg-grime -z-10" buffered />
      <div class="text-white">
        <div class="flex min-h-screen justify-center items-center">
          <div class="w-[38rem] p-8 bg-grime-100 border border-grime-200 monolith-4 monolith-grime-150">
            <Shaded class="text-5xl text-center mb-4">Duerden.dev</Shaded>
            <h2>Developer from the UK 🇬🇧</h2>
            <Pb />
            <h4 class="underline decoration-grime-200">
              I have experience with:
            </h4>
            <div class="flex flex-row flex-wrap items-center justify-center gap-2">
              <Card>React & Preact</Card>
              <Card>PHP</Card>
              <Card>Lisp</Card>
              <Card>Odoo (Some)</Card>
              <Card>Javascript</Card>
              <Card>JS Tooling (Webpack, etc)</Card>
              <Card>CNC</Card>
              <Card>OpenSCAD</Card>
            </div>
            <Pb />
            <h4 class="underline decoration-grime-200">
              I'm currently working at{" "}
              <FunnyLink href="http://www.atpprojects.co.uk/">
                ATP Projects
              </FunnyLink>
            </h4>
            <Pb />
            <h3>Public Projects:</h3>
            <ul>
              <li class="font-bold">
                <FunnyLink href="https://git.duerden.dev/">
                  Git Repository
                </FunnyLink>
              </li>
              <li class="font-bold">
                <FunnyLink href="/blog">Blog</FunnyLink>
              </li>
              <li>
                <FunnyLink href="https://localvcomp.duerden.dev/">
                  localvcomp
                </FunnyLink>{" "}
                - Run ffmpeg in the browser, for video compression
              </li>
              <li>
                <FunnyLink href="https://git.duerden.dev/d/web-gcode-sender">
                  WGS
                </FunnyLink>{" "}
                - Send GCode a CNC controller, from your browser!
              </li>
              <li>
                <FunnyLink href="https://git.duerden.dev/d/better-sda">
                  better-sda
                </FunnyLink>{" "}
                - Terminal-based Steam TOTP authenticator
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export { IndexPage };
