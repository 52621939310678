import { useRef, useState, useLayoutEffect } from "preact/hooks";
import { useEffect } from "react";
import { Field } from "@/lib/field.js";
import { createNoise2D } from "simplex-noise";
import alea from "alea";

import isMobile from "@/lib/isMobile";

// https://stackoverflow.com/questions/61118060/how-to-access-tailwind-colors-from-javascript
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "tailwind.config.js";
const {
  theme: { colors: colours },
} = resolveConfig(tailwindConfig);

function useResolution(padding) {
  var [size, setSize] = useState({
    width: null,
    height: null,
  });

  useLayoutEffect(() => {
    function handleResize() {
      const docHeight = Math.max(
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight,
        document.documentElement.clientHeight
      );

      setSize({
        width: window.innerWidth + padding,
        height: docHeight + padding,
      });
    }

    // Initial size
    handleResize();

    // Handle window resize
    window.addEventListener("resizeend", handleResize);

    // Handle content changes that might affect scroll height
    const observer = new ResizeObserver(handleResize);
    observer.observe(document.documentElement);

    return () => {
      window.removeEventListener("resizeend", handleResize);
      observer.disconnect();
    };
  }, []);

  return size;
}

/*
 * available state:
 * buffered - enables draw buffering for full-page backgrounds. this is useful as resizing a window would cause a portion of the un-drawn background to show, this enables "overdraw" to show while the window gets resized.
 */
function BackgroundCanvas(props) {
  if (props.buffered) {
    var pxbuf = 100;
    var style = {
      position: "absolute",
      top: `-${pxbuf}px`,
      left: `-${pxbuf}px`,
      width: `calc(100% + ${pxbuf * 2}px)`,
      height: `calc(100% + ${pxbuf * 2}px)`,
      pointerEvents: "none",
    };
  }

  var { width, height } = useResolution(props.buffered ? pxbuf * 2 : 0);
  var canvasRef = useRef(null);

  useEffect(() => {
    if (!width || !height) {
      return;
    }
    var field = new Field({
      noiseScale: 1e-3 / (height / 1080),
      strokeCount: 1000 * (height / 1080),
      customColour: colours.grime[200],
      strokesPerDraw: isMobile() ? 100 : 1000,
      canvas: canvasRef.current,
      resolution: { width, height },
      noise: createNoise2D(),
    });

    field.render(canvasRef.current, { width, height });

    return () => {
      field.stopRender();
    };
  }, [width, height]);

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      {...props}
      style={style}
    />
  );
}

export { BackgroundCanvas };
