import { createNoise2D } from "simplex-noise";

function randBetween(min,max){
	return Math.random() * ( max - min ) + min ;
}

async function timeout(ms){ return new Promise( (resolve)=>setTimeout(resolve, ms) ) }

function getPalette(e){return e}

class Field {
	constructor(cfg={}, noise=(new createNoise2D())  ){
		this.CONFIG = Object.assign({
			canvas: null,
			resolution: { width: null, height: null },

			noiseScale: 1e-3,
			noiseIteration: 1,

			colourBase: null,
			customColour: null,

			strokeCount: 1000,
			strokesPerDraw: 100,
			maxStrokeLength: 0.9,
			minStrokeThickness: 0.3,
			maxStrokeThickness: 0.5,
			strokeSegmentLength: 10,
		}, cfg);
		this.allowRender = true;
		this.noise = this.CONFIG.noise ?? noise;
		this.lines_drawn = 0;
		this.ctx = this.CONFIG.canvas.getContext("2d");

		//window.requestAnimationFrame rebinds `this` on functions, meaning self-calling methods fail.
		// this is a kinda hacky way around it.
		this.renderStep = this.renderStep.bind(this);

		console.log("[Field] Started with config:", this.CONFIG)
	}

	clear(){
		var {width, height} = this.CONFIG.resolution;
		this.ctx.clearRect(0, 0, width, height);
	}

	getSampleAt(x,y){
		return this.noise(
			x * this.CONFIG.noiseScale,
			y * this.CONFIG.noiseScale
		) * Math.PI * 2;
	}

	async drawLines(){
		if( this.lines_drawn >= this.CONFIG.strokeCount ) { return }

		var {width, height} = this.CONFIG.resolution;
		for( var sIdx = 0; sIdx < this.CONFIG.strokesPerDraw; sIdx++ ){
			var splat = {
				x: Math.random() * width,
				y: Math.random() * height
			}

			this.ctx.strokeStyle = this.CONFIG.customColour ?? getPalette(this.CONFIG.colourBase) ?? `rgba(255,255,255,0.25)`;
			this.ctx.lineWidth = randBetween(this.CONFIG.minStrokeThickness, this.CONFIG.maxStrokeLength);
			this.ctx.beginPath();
			this.ctx.moveTo(splat.x, splat.y);

			var leftToDraw = this.CONFIG.maxStrokeLength * width;
			while( leftToDraw > 0 ){
				var sample = this.getSampleAt(splat.x, splat.y);
				var end = {
					x: splat.x + Math.cos(sample) * this.CONFIG.strokeSegmentLength,
					y: splat.y + Math.sin(sample) * this.CONFIG.strokeSegmentLength
				};

				// keep in bounds
				if(
					end.x < 0 || end.x > width ||
					end.y < 0 || end.y > height
				){
					leftToDraw = 0;
					break;
				}
				this.ctx.lineTo( end.x, end.y );
				splat = { x: end.x, y: end.y };
				leftToDraw -= this.CONFIG.strokeSegmentLength;

				if(false){
					await timeout(10);
					this.ctx.stroke();
				}

			}

			this.ctx.stroke();
			this.lines_drawn++;
		}
	}

	async renderStep(){
		await this.drawLines();
		if( this.lines_drawn <= this.CONFIG.strokeCount ) {
			window.requestAnimationFrame(this.renderStep);
		}
	}

	async render(){
		if(!this.CONFIG.resolution.width){ return }
		console.log("[Render]", this.CONFIG.resolution);
		var {width, height} = this.CONFIG.resolution;

		this.lines_drawn = 0;

		//execute
		this.clear();
		this.renderStep();
	}


	stopRender(){
		this.allowRender = false;
	}
}

export { Field }