import { useRef, useState, useLayoutEffect } from "preact/hooks";
import { useEffect } from "react";
import { Field } from "@/lib/field.js";
import { createNoise2D } from "simplex-noise";
import alea from "alea";

import isMobile from "@/lib/isMobile";

// https://stackoverflow.com/questions/61118060/how-to-access-tailwind-colors-from-javascript
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "tailwind.config.js";
const {
  theme: { colors: colours },
} = resolveConfig(tailwindConfig);

function useResolution(padding) {
  var [size, setSize] = useState({
    width: null,
    height: null,
  });

  useLayoutEffect(() => {
    function handleResize() {
      setSize({
        width: window.innerWidth + padding,
        height: window.innerHeight + padding,
      });
    }

    handleResize();
    window.addEventListener("resizeend", handleResize);
    return () => window.removeEventListener("resizeend", handleResize);
  }, []);

  return size;
}

/*
 * available state:
 * buffered - enables draw buffering for full-page backgrounds. this is useful as resizing a window would cause a portion of the un-drawn background to show, this enables "overdraw" to show while the window gets resized.
 */
function BackgroundCanvas(props) {
  if (props.buffered) {
    var pxbuf = 100;
    var style = { inset: `-${pxbuf}px` };
  }

  var { width, height } = useResolution(props.buffered ? pxbuf * 2 : 0);
  var canvasRef = useRef(null);

  useEffect(() => {
    if (!width || !height) {
      return;
    }
    var field = new Field({
      noiseScale: 1e-3 / (height / 1080),
      strokeCount: 1000 * (height / 1080),
      customColour: colours.grime[200],
      strokesPerDraw: isMobile() ? 100 : 1000 /* phones are slow */,
      canvas: canvasRef.current,
      resolution: { width, height },
      noise: createNoise2D(/* alea(navigator.userAgent) */),
    });

    field.render(canvasRef.current, { width, height });

    return () => {
      field.stopRender();
    };
  }, [width, height]);

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      {...props}
      style={style}
    />
  );
}

export { BackgroundCanvas };
