import { render } from "preact";
import {
  lazy,
  LocationProvider,
  ErrorBoundary,
  Router,
  Route,
} from "preact-iso";

import "./style.css";
import "./fonts/font.css";

import "./lib/js_hot_reload.js";
import "./lib/resizeEnd.js";

//depend index
import { IndexPage } from "./pages/Index.jsx";
import { NotFound } from "./pages/ExtraPages.jsx";
import { BlogList, BlogPost } from "./pages/Blog.jsx";

//lazy
// const Posts = lazy( ()=>import('./pages/post_list.jsx') )

render(
  <LocationProvider>
    <ErrorBoundary>
      <Router>
        <Route path="/" component={IndexPage} />
        <Route path="/blog" component={BlogList} />
        <Route path="/blog/:id" component={BlogPost} />
        <NotFound default />
      </Router>
    </ErrorBoundary>
  </LocationProvider>,
  document.body
);
