/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ["./src/**/*.html", "./src/**/*.{js,jsx}"],
  theme: {
    extend: {
      colors: {
        grime: {
          50: "#192123",
          100: "#293133", // background
          200: "#465350", // alternate
        },
        crimson: {
          // general accent
          100: "#ffccd5",
          200: "#ffb3c1",
          300: "#ff8fa3",
          400: "#ff758f",
          500: "#ff4d6d",
          600: "#c9184a",
          700: "#a4133c",
          700: "#800f2f",
          800: "#590d22",
          900: "#c9181e",
        },
      },
      fontFamily: {
        sans: ["Montserrat"],
      },
    },
  },
  variants: {},
  plugins: [],
};
