// https://forum.jquery.com/portal/en/community/topic/the-resizeend-event

var rtime;
var timeout = false;
var delta = 200;
window.addEventListener("resize",function() {
    rtime = new Date();
    if (timeout === false) {
        timeout = true;
        setTimeout(resizeend, delta);
    }
});

function resizeend() {
    if (new Date() - rtime < delta) {
        setTimeout(resizeend, delta);
    } else {
        timeout = false;
        console.log("wawa done");
        window.dispatchEvent(new CustomEvent("resizeend"))
    }               
}