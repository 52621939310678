const requirePosts = require.context("./Posts", false, /\.mdx$/);
const Posts = requirePosts.keys().map((path) => ({
  ...requirePosts(path),
  slug: path.split("/").pop().replace(".mdx", ""),
}));

import { useLocation, useRoute } from "preact-iso";
import { BackgroundCanvas } from "£/BackgroundCanvas.jsx";
import { Icon } from "£/Icon.jsx";
import { Woobly } from "£/Woobly.jsx";
export function BlogList() {
  const { route } = useLocation();
  return (
    <div class="relative min-h-screen overflow-hidden">
      <BackgroundCanvas class="bg-grime -z-10" buffered />
      <div class="text-white">
        <div class="flex min-h-screen justify-center items-center py-8">
          <div class="w-[38rem] p-8 bg-grime-100 border border-grime-200 monolith-4 monolith-grime-150">
            <div class="relative mb-4">
              <button
                onClick={() => route("/")}
                class="absolute left-1 top-1 px-2 py-1 hover:bg-grime-200 bg-grime-100 transition-colors aspect-square rounded-full"
              >
                <Icon icon="arrow-left" />
              </button>
              <h1 class="text-4xl text-center">Blog Posts</h1>
            </div>
            <div class="grid grid-cols-2 gap-4">
              {Posts.map((post) => (
                <div
                  key={post.slug}
                  onClick={() => route(`/blog/${post.slug}`)}
                  class="group bg-grime-100 p-2 border border-grime-200 cursor-pointer hover:bg-grime-50 transition-colors hover:monolith-2 monolith-grime-200"
                >
                  <h2 class="text-xl group">
                    <div class="group-hover:hidden">{post.title}</div>
                    <div class="hidden group-hover:block">
                      <Woobly>{post.title}</Woobly>
                    </div>
                  </h2>
                  <p>{post.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function BlogPost() {
  const { id } = useRoute();
  const { route } = useLocation();
  const post = Posts.find((post) => post.slug === id);
  return (
    <div class="relative min-h-screen overflow-hidden">
      <BackgroundCanvas class="bg-grime -z-10" buffered />
      <div class="text-white">
        <div class="flex min-h-screen justify-center items-center py-8">
          <div class="w-[80%] min-w-[38rem] max-w-[80rem] p-8 bg-grime-100 border border-grime-200 monolith-4 monolith-grime-150">
            <div class="relative mb-4">
              <button
                onClick={() => route("/blog")}
                class="absolute left-1 top-1 px-2 py-1 hover:bg-grime-200 bg-grime-100 transition-colors aspect-square rounded-full"
              >
                <Icon icon="arrow-left" />
              </button>
              <h1 class="text-4xl text-center">{post.title}</h1>
            </div>
            <div class="prose prose-invert">
              <post.default />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
