import { BackgroundCanvas } from "£/BackgroundCanvas.jsx";
import { useReducer, useState } from "preact/hooks";

function /* Page Break */ Pb() {
  return <div class="mt-4" />;
}

function Hl({ children }) {
  return (
    <span class="bg-grime-50 p-[0.2em] rounded-sm italic">{children}</span>
  );
}

function Quote({ author, children }) {
  return (
    <li class="list-none">
      <q>{children}</q>
      <br />
      <i class="block text-right">~ {author}</i>
    </li>
  );
}

function Title({ children }) {
  return (
    <h1 id="title" class="text-5xl text-center mb-4">
      {children.split("").map((a, i) => (
        <span
          /* this is ugly, maybe replace with attr()? */ style={{
            animationDelay: `${-(children.length - i) / 4}s`,
          }}
        >
          {a}
        </span>
      ))}
    </h1>
  );
}

const facts = [
  "My cat invented the number 7.",
  "Gecko doesnt render var-width fonts correctly on linux/android.",
  "The first programming language I learned was (unfortunately) PHP.",
  "I'm apt with PHP, Javascript, bash, & headless linux 'stuff'.",
  "The best way to learn how good someone is at task management is to play Factorio with them.",
  <>
    The <a href="/blog/background">background</a> of this page is not an image!
    Try refreshing...
  </>,
];

function IndexPage() {
  var [fact, newFact] = useReducer((state, act) => {
    return Math.floor(Math.random() * facts.length);
  }, Math.floor(Math.random() * facts.length));

  var [expanded, setExpanded] = useState(false);

  function expand() {
    setExpanded(true);
  }

  return (
    <>
      <BackgroundCanvas class="fixed bg-grime" buffered />
      <div class="inset-0 fixed text-white flex flex-col justify-center items-center">
        <div
          class="w-[38rem] p-8 bg-grime-100 border border-grime-200"
          style={{
            "border-bottom-style":
              "dotted" /* tailwind has no support for border-bottom-style ?! */,
          }}
        >
          <Title>Duerden.dev</Title>
          <h2>Developer from 🇬🇧</h2>
          <Pb />
{/*          <p onClick={newFact}>{facts[fact]}</p>
          <Pb /> */}
          <h3>Projects:</h3>
          <ul>
            <li class="font-bold">
              <a href="https://git.duerden.dev/">Git Repository</a>
            </li>
            <li>
              <a href="https://localvcomp.duerden.dev/">localvcomp</a> - Run
              ffmpeg in the browser, for video compression
            </li>
            <li>
              <a href="https://git.duerden.dev/d/web-gcode-sender">WGS</a> -
              Send GCode a CNC controller, from your browser!
            </li>
            <li>
              <a href="https://git.duerden.dev/d/better-sda">better-sda</a> -
              Terminal-based Steam TOTP authenticator
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

/*
        <div
          class="w-[38rem] bg-grime-100 border border-t-0 border-grime-200 p-8 relative"
          style={{ cursor: expanded ? "" : "pointer" }}
          onClick={expand}
        >
          <h2>Testimonies:</h2>
          {!expanded && (
            <div class="absolute right-[50%] top-[50%] translate-y-[-50%]">
              <Hl>Click to expand</Hl>
            </div>
          )}
          <div
            style={{ height: `${expanded ? "100%" : "0px"}` }}
            class="overflow-hidden"
          >
            <Pb />
            <ul class="list-outside ml-[1em]">
              <Quote author="anonymous">
                <Hl>forgot about this</Hl> is a really cool commit message
              </Quote>
              <Quote author="anonymous">
                You're a colossal moron for using javascript to do this
              </Quote>
              <Quote author="TeamSpeakUser">
                Sometimes I feel like you put actual effort into things just to
                prove a point about it being a bad idea.
              </Quote>
              <Quote author="anonymous">
                He's the epitomy of the phrase{" "}
                <Hl>"do as I say, not as I do"</Hl>
              </Quote>
            </ul>
          </div>
        </div>

*/

export { IndexPage };
