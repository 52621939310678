// CRC32 sourced from github.com/allex/crc32-js (minified)
// licence: MIT
const crc32b = (function () {
    const r = new Uint32Array(256);
    for (let t = 256; t--; ) {
      let e = t;
      for (let r = 8; r--; ) e = 1 & e ? 3988292384 ^ (e >>> 1) : e >>> 1;
      r[t] = e;
    }
    return (t) => {
      let e = -1;
      "string" == typeof t &&
        (t = (function (r) {
          const t = r.length,
            e = new Array(t);
          for (var n = -1; ++n < t; ) e[n] = r.charCodeAt(n);
          return e;
        })(t));
      for (let n = 0, c = t.length; n < c; n++)
        e = (e >>> 8) ^ r[(255 & e) ^ t[n]];
      return (-1 ^ e) >>> 0;
    };
  })(),
  hex = (r) => (
    r < 0 && (r = 4294967295 + r + 1), ("0000000" + r.toString(16)).slice(-8)
  ),
  crc32 = (r, t) => {
    const e = crc32b(r);
    return t ? hex(e) : e;
  };

fetch(document.getElementsByTagName("script")[0].src + ".map")
  .then((res) => {
    if (!res.ok) {
      throw new Error("err");
    }
    run();
  })
  .catch((e) => {
    //do nothing, we could alert to say we have been bundled in production
  });

function run() {
  var targets = ["/index.html", "/bundle.js", "/styles.css"];

  var previousRun = Object.fromEntries(targets.map((a) => [a, null]));

  // returns a unique blob of data based on the input.
  async function uniq(message) {
    // for some godforsaken reason, crypto.subtle is gated behind secure contexts.
    // i really dont understand why its gated behind https, it seems like a weird choice.
    // i understand gating things like webusb, workers behind secure context because you
    // could actually do some damage with those, like lagging out the computer with workers
    // or getting someone clueless to connect their phone and click "allow usb".
    // but crypto.suble? god forbid someone generate a hash! so dangerous!

    if (window.isSecureContext) {
      const msgUint8 = new TextEncoder().encode(message);
      const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");
      return hashHex;
    } else {
      return crc32(message);
      //fallback to a slower non-native checksum
    }
  }

  setInterval(() => {
    targets.forEach(async (target) => {
      try {
        var f = await fetch(window.location.origin + target);
        if (!f.ok) {
          throw new Error(
            `${target}: Waiting for new resource... (${f.status} != 200)`
          ); //jump to catch, which returns.
        }
        var data = await f.text();
      } catch (e) {
        var f = { status: 404 };
      }

      if (f.status != 404 && target.endsWith(".html")) {
        //cloudflare xss's its sites for turnstile, we ignore.
        data = data.replace(
          /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script\s*>/gi,
          ""
        );
      }
      //var hash = await uniq(data);

      // if we dont have a previous run, set it here.
      if (previousRun[target] == null) {
        previousRun[target] = f.status;
      }

      console.log(previousRun[target], "&&", f.status);

      if (previousRun[target] == 404 && f.status == 200) {
        console.log(target, "triggered a reload");
        window.location.reload(true);
      }

      previousRun[target] = f.status;
    });
  }, 1000);
}
