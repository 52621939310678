/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ["./src/**/*.{js,jsx,mdx,html}"],
  theme: {
    extend: {
      colors: {
        grime: {
          50: "#192123",
          100: "#293133", // background
          150: "#34413d", // background
          200: "#465350", // alternate
        },
        crimson: {
          // general accent
          100: "#ffccd5",
          200: "#ffb3c1",
          300: "#ff8fa3",
          400: "#ff758f",
          500: "#ff4d6d",
          600: "#c9184a",
          700: "#a4133c",
          700: "#800f2f",
          800: "#590d22",
          900: "#c9181e",
        },
      },
      fontFamily: {
        sans: ["Montserrat"],
      },
      backgroundImage: {
        hazard: `repeating-linear-gradient(45deg,
					var(--hazard-secondary, transparent), var(--hazard-secondary, transparent) 25%,
					var(--hazard-primary, #ff0000) 25%, var(--hazard-primary, #ff0000) 50%,
					var(--hazard-secondary, transparent) 50%, var(--hazard-secondary, transparent) 75%,
					var(--hazard-primary, #ff0000) 75%, var(--hazard-primary, #ff0000) 100%)`,
      },
      backgroundSize: {
        hazard: "8px 8px",
      },
    },
  },
  variants: {},
  plugins: [
    function ({ addUtilities, theme }) {
      const hazardPrimary = Object.entries(theme("colors")).reduce(
        (acc, [color, values]) => {
          if (typeof values === "object") {
            Object.entries(values).forEach(([shade, value]) => {
              acc[`.hazard-primary-${color}-${shade}`] = {
                "--hazard-primary": value,
              };
            });
          }
          return acc;
        },
        {}
      );

      const hazardSecondary = Object.entries(theme("colors")).reduce(
        (acc, [color, values]) => {
          if (typeof values === "object") {
            Object.entries(values).forEach(([shade, value]) => {
              acc[`.hazard-secondary-${color}-${shade}`] = {
                "--hazard-secondary": value,
              };
            });
          }
          return acc;
        },
        {}
      );

      addUtilities(hazardPrimary);
      addUtilities(hazardSecondary);
      addUtilities({
        ".hazard-scroll": {
          animation: "hazard-scroll 1s linear infinite",
        },
      });
    },

    function ({ addUtilities, theme }) {
      const monolithUtils = {};
      const colors = theme("colors");

      const getShadow = (index, direction = "br") => {
        const offset = index + 1;
        switch (direction) {
          case "tr":
            return `${offset}px -${offset}px 0px var(--monolith-color, #000)`;
          case "tl":
            return `-${offset}px -${offset}px 0px var(--monolith-color, #000)`;
          case "br":
            return `${offset}px ${offset}px 0px var(--monolith-color, #000)`;
          default:
          case "bl":
            return `-${offset}px ${offset}px 0px var(--monolith-color, #000)`;
        }
      };

      ["tr", "tl", "br", "bl"].forEach((direction) => {
        monolithUtils[`.monolith-${direction}`] = {
          "--monolith-direction": direction,
        };
      });

      Object.entries(colors).forEach(([colorName, colorValues]) => {
        if (typeof colorValues === "object") {
          Object.entries(colorValues).forEach(([shade, value]) => {
            monolithUtils[`.monolith-${colorName}-${shade}`] = {
              "--monolith-color": value,
            };
          });
        }
      });

      for (let i = 1; i <= 16; i++) {
        ["tr", "tl", "br", "bl"].forEach((direction) => {
          const shadows = Array.from({ length: i }, (_, index) =>
            getShadow(index, direction)
          ).join(", ");

          const offset = i;
          let transform;
          switch (direction) {
            case "tr":
              transform = `translate(-${offset}px, ${offset}px)`;
              break;
            case "tl":
              transform = `translate(${offset}px, ${offset}px)`;
              break;
            case "br":
              transform = `translate(-${offset}px, -${offset}px)`;
              break;
            case "bl":
              transform = `translate(${offset}px, -${offset}px)`;
              break;
          }

          monolithUtils[`.monolith-${i}.monolith-${direction}`] = {
            "box-shadow": shadows,
            transform,
          };
        });

        const defaultShadows = Array.from({ length: i }, (_, index) =>
          getShadow(index, "br")
        ).join(", ");

        monolithUtils[`.monolith-${i}`] = {
          "box-shadow": defaultShadows,
          transform: `translate(-${i}px, -${i}px)`,
        };
      }

      addUtilities(monolithUtils);
    },
  ],
};
